import styled from 'styled-components';
import theme from '../../assets/theme';

const Button = styled.button`
  transition: all 0.3s ease;
  background: ${(props) =>
    theme.bgColors[Object.keys(props).find((color) => theme.bgColors[color])] ||
    theme.bgColors.primary};
  text-transform: ${(props) => (props.upper ? 'uppercase' : 'none')};
  font-weight: 300;
  color: ${(props) =>
    theme.colors[Object.keys(props).find((color) => theme.colors[color])] ||
    theme.colors.default};
  padding: 15px 30px;
  margin-inline: ${(props) => (props.left ? '' : 'auto')};
  margin-top: ${(props) => (props.upper ? '20px' : '')};
  border: none;
  border-radius: 0;
  font-family: 'Neutraface';
  pointer-events: ${(props) => (props.pointer === 'no-drop' ? 'none' : 'auto')};
  cursor: ${(props) => (props.pointer ? props.pointer : 'pointer')};
  height: ${(props) => (props.height ? '56px' : 'auto')};
  width: ${(props) => (props.large ? '100%' : '190px')};
  font-size: 16px;
  text-align: center;
  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 992px) {
    margin-inline: ${(props) => (props.left ? 'auto' : '')};
  }

  @media (max-width: 768px) {
    margin-inline: ${(props) => (props.left ? 'auto' : '')};
    position: ${(props) => (props.responsive ? 'absolute' : '')};
    bottom: ${(props) => (props.mblresponsive ? 'calc(100vh - 75%)' : '')};
    width: ${(props) => (props.large ? '100%' : '265px')};
    left: ${(props) => (props.responsive ? '50%' : '')};
    transform: ${(props) => (props.responsive ? 'translateX(-50%)' : '')};
  }
`;

export default Button;
