import React from 'react';
import Button from '../../components/generic/button';
import RegisterModal from '../modal/RegisterModal';
import useModal from '../../hooks/useModal';
import {
  Para,
  MobilePara,
  Lines,
  Line,
} from '../../components/styledComponent';
import Container from '../../components/generic/container';
import { ASSETS_CDN_URL } from '../../constants/constant';
import {
  HeroContent,
  MobileTitle,
  Overlay,
  Title,
  Video,
  HomeSection,
} from './HomeStyled';

const HeroSection = () => {
  const { isShowing, toggle } = useModal();
  return (
    <>
      <Container>
        <Lines>
          <Line />
          <Line />
          <Line />
          <Line mblHide />
        </Lines>
      </Container>
      <HomeSection>
        <Overlay />
        <Video autoPlay loop muted playsInline>
          <source src={ASSETS_CDN_URL + 'hero-video.mp4'} type="video/mp4" />
        </Video>
        <HeroContent>
          <MobileTitle>SHELBY ENTERS THE METAVERSE</MobileTitle>
          <Title>SHELBY AMERICAN ENTERS THE METAVERSE</Title>
          <MobilePara>
            Sign up with your Cardano wallet and email for a chance to win one
            of just 60 limited edition Shelby Cobra 260 “CSX 2000” NFTs!
          </MobilePara>
          <Para mbl heroPara>
            Be one of the first to know when Shelby launches in the Virtua
            metaverse! Sign up with your Cardano wallet and email for a chance
            to win one of just 60 Shelby Cobra 260 “CSX 2000” NFTs – the
            legendary and very first Shelby Cobra!
          </Para>

          <Button
            onClick={toggle}
            gradients
            upper
            props
            responsive
            mblresponsive
          >
            sign up
          </Button>
          <RegisterModal isShowing={isShowing} hide={toggle} />
        </HeroContent>
        <div className="downarrow">
          <Para footer>Scroll Down To Get Free NFT</Para>
        </div>
      </HomeSection>
    </>
  );
};

export default HeroSection;
