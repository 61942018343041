import joi from "joi";

export const UserSchema = joi
  .object({
    email: joi.string().email({
      tlds: false
    }).trim().allow(null, '').optional().messages({
      'string.email': "Email is invalid."
    }),
    cardano_wallet: joi.string().trim().required().messages({
      'string.empty': "Wallet address cannot be empty."
    }),
    email_allowed: joi.bool().optional()
  });
