import React from 'react';
import styled from 'styled-components';
import { Logo, Virtua } from '../assets/images';
import Container from './generic/container';

const Nav = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 30px;
  position: ${(props) => (props.fixed ? 'fixed' : 'relative')};
  z-index: 2;
  @media (max-width: 768px) {
    padding-inline: 0;
  }
  @media (max-height: 700px) {
    padding-top: 15px;
  }
`;

const HeaderLogo = styled.img`
  width: ${(props) => (props.virtua ? '120px' : 'auto')};
  @media (max-width: 768px) {
    width: ${(props) => (props.virtua ? '100px' : '120px')};
  }
`;

const Right = styled.div`
  margin-left: auto;
`;

const Header = () => {
  return (
    <Container>
      <Nav>
        <a href="/">
          <HeaderLogo src={Logo} alt="logo" />
        </a>
        <Right>
          <HeaderLogo virtua src={Virtua} alt="virtua-logo" />
        </Right>
      </Nav>
    </Container>
  );
};

export default Header;
