import React from 'react';
import styled from 'styled-components';
import { SOCIAL_ICONS } from '../constants/constant';

import Container from './generic/container';
import { Para, SocialLinks } from './styledComponent';

const FooterContent = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  position: fixed;
  z-index: 2;
  bottom: 0;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding-bottom: 10px;
    padding-inline: 0;
    gap: 10px;
    margin-left: -16px;
  }
  @media (max-height: 700px) {
    padding-bottom: 10px;
  }
`;

const Right = styled.div`
  @media (max-width: 768px) {
    margin-left: 0;
  }
  margin-left: auto;
  display: flex;
  gap: 10px;
`;
const Icon = styled.img`
  width: 20px;
  height: 20px;
  @media (max-width: 600px) {
    width: 15px;
    height: 15px;
  }
`;

const Footer = () => {
  return (
    <Container>
      <FooterContent>
        <Para footer>
          © Shelby American & Virtua 2022. All Rights Reserved.
        </Para>
        <Right social>
          {SOCIAL_ICONS.map((item) => (
            <SocialLinks key={item.alt} href={item.href} target="_blank">
              <Icon src={item.src} alt={item.alt} />
            </SocialLinks>
          ))}
        </Right>
      </FooterContent>
    </Container>
  );
};

export default Footer;
