import styled from 'styled-components';

const Container = styled.div`
  max-width: 1330px;
  margin: 0 auto;
  position: fixed;
  z-index: 2;
  width: 100%;
  left: 50%;
  transform: translate(-50%);
  @media (max-width: 1380px) {
    max-width: 1024px;
  }
  @media (max-width: 1024px) {
    max-width: 768px;
  }
  @media (max-width: 768px) {
    padding-inline: 25px;
  }
`;

export default Container;
