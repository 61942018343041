import styled from 'styled-components';

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: none;
    z-index: 400;
    &.active{
        display: block;
    }
  `;
const ModalDialog = styled.div`
    min-height: calc(100% - 3.5rem);
    max-width: 520px;
    margin: 1.75rem auto;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  `;
const Loader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    img{
      max-width: 60px;
    }
  `;
const ModalHolder = styled.div`
    background: #141414;
    width: 100%;
    padding: 60px 30px 50px;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(90deg, #B26EE4 0%, #F1209B 100%);
      filter: blur(25px);
      opacity: 0.5;
      z-index: -2;
    }
    &:after{
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background: linear-gradient(90deg, rgba(178, 110, 228, 0.4) 0%, rgba(241, 32, 155, 0.4) 100%);
      z-index: -1;
    }
    @media (max-width: 600px){
      width: 90vw;
      padding: 50px 17px 30px;
      margin: 0 auto;
      &:before{
        filter: blur(15px);
      }
    }
  `;
const CrossIcon = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  `;
const Heading = styled.h2`
    color: #fff;
    font-size: ${props => props.fontSize || "30px"};
    font-weight: 400;
    line-height: 1.3;
    margin: 0 0 16px;
    font-family: 'Neutraface',serif;
  @media (max-width: 1400px){
    font-size: 28px;
  }
  @media (max-width: 600px){
    font-size: 22px;
  }
  `;
const HeadingTitle = styled(Heading)`
    color: #00EB92;
    font-size: 36px;
    margin: 0 0 10px;
  @media (max-width: 1400px){
    font-size: 30px;
  }
  @media (max-width: 600px){
    font-size: 24px;
  }
  `;

export {Modal,ModalDialog,ModalHolder,Loader,CrossIcon,Heading,HeadingTitle};
