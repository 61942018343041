import styled from 'styled-components';
import { HeroMblBg } from '../../assets/images';

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: #0a0113;
  opacity: 0.5;
  z-index: 1;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Video = styled.video`
  height: ${(props) => (props.cardano ? '' : '100vh')};
  width: 100%;
  object-fit: cover;
  top: 0;
  border-radius: ${(props) => (props.cardano ? '15px' : '')};
  @media (max-width: 768px) {
    display: ${(props) => (props.cardano ? 'block' : 'none')};
  }
`;

const HeroContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 830px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 2;
  text-align: center;
  width: 100%;
  @media (max-width: 1200px) {
    max-width: 850px;
  }
  @media (max-width: 992px) {
    max-width: 650px;
  }
  @media (max-width: 768px) {
    padding-inline: 20px;
    height: 100vh;
    padding-top: 130px;
    gap: 16px;
  }
`;

const Title = styled.h1`
  font-family: 'Neutraface', serif;
  margin: 0;
  font-weight: 900;
  color: #fff;
  font-size: 65px;
  display: block;
  line-height: 64px;
  @media (max-width: 768px) {
    font-size: 25px;
    line-height: 25px;
    display: none;
  }
  @media (max-width: 1400px) {
    font-size: 42px;
    line-height: 42px;
  }
`;
const MobileTitle = styled(Title)`
  display: none;
  @media (max-width: 768px) {
    display: block;
    font-size: 32px;
  }
`;

const HomeSection = styled.section`
  position: relative;
  height: 100vh;
  @media (max-width: 768px) {
    background: url(${HeroMblBg}) no-repeat;
    background-size: cover;
  }
`;

export { Overlay, Video, HeroContent, Title, MobileTitle, HomeSection };
