import React from 'react';
import ReactDOM from 'react-dom';
import RegisterForm from "../register-form/RegisterForm";

const RegisterModal = ({ isShowing, hide }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
    <RegisterForm hide={hide}/>
  </React.Fragment>, document.body
) : null;

export default RegisterModal;
