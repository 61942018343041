import React, { useEffect, useState } from 'react';
import './style.css';
import { ErrorInputMessage, Input } from '../../components/form/input';
import Button from '../../components/generic/button';
import { Cross, congratsIcon, loaderGif } from '../../assets/images';
import useRegister from "../../hooks/useRegister";
import { UserSchema } from '../../utils/validations';
import { usersAction } from '../../state/register/registerActions';
import {CrossIcon, Heading, HeadingTitle, Loader, Modal, ModalDialog, ModalHolder} from "./RegisterFormStyled";


const RegisterForm = ({ hide }) => {

  const [step, setStep] = useState(0);
  const [wallet, setWallet] = useState("");
  const [email, setEmail] = useState("");
  const [emailAllowed, setEmailAllowed] = useState(false);
  const [register, setFormData, isLoading, dispatch] = useRegister();
  const [errorMessage, setErrorMessage] = useState(false);

  const [errors, setErrors] = useState({})

  const validate = () => UserSchema.validate({ email, cardano_wallet: wallet, email_allowed: emailAllowed }, { abortEarly: false });

  const handleSubmit = async () => {
    let response = validate();
    if (!response.error) {
      setErrors({});
      setFormData(response.value);
    } else {
      let errors = {};
      response.error.details.forEach(item => {
        errors[item.context.key] = item.message
      });
      setErrors(errors);
    }

  }

  useEffect(() => {
    if (register.users?.statusCode >= 200 && register.users?.statusCode < 299) {
      setStep(1)
      dispatch(usersAction(null));
    } else if (register.users?.statusCode === 400) {
      setErrorMessage(true)
    }
  }, [register, dispatch]);


  return (
    <Modal className="flex items-center flex-text-center active">
      <ModalDialog>
        <ModalHolder className="relative">
          <div className="inner-content">
              {step === 0 && (
                <>
                  {errorMessage && <ErrorInputMessage>{register.users?.data?.message}</ErrorInputMessage>}
                  <Heading>REGISTER INTEREST</Heading>
                  <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <Input
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="cardano@example.com"
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                      value={email}
                    />
                    {errors.email ? (
                      <ErrorInputMessage>{errors.email} </ErrorInputMessage>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor="address">Wallet Address<em className="required-field">*</em></label>
                    <Input
                      id="address"
                      type="text"
                      name="cardano_wallet"
                      className="form-control"
                      placeholder="Enter Wallet Address"
                      autoComplete='off'
                      onChange={(e) => {
                        setWallet(e.target.value)
                      }}
                      value={wallet}
                    />
                    {errors.cardano_wallet ? (
                      <ErrorInputMessage>{errors.cardano_wallet} </ErrorInputMessage>
                    ) : null}
                  </div>
                  <div className="custom-checkbox flex items-center">
                    <input type="checkbox" id="accept-terms" className="hidden" onClick={(e)=> {
                      setEmailAllowed(e.target.checked)
                    }} />
                    <label htmlFor="accept-terms" className="relative">
                      Subscribe to receive updates from Virtua
                    </label>
                  </div>
                  {step === 0 && <>
                    <div className="btn-holder flex flex-wrap">
                      <Button className='uppercase' large height={25} gradients onClick={handleSubmit} type="submit">
                        Submit
                      </Button>
                    </div>
                  </>}
                </>
              )}

              {step === 1 && <div className="congratulation text-center">
                <img src={congratsIcon} alt="congratulation" />
                <HeadingTitle>CONGRATULATIONS</HeadingTitle>
                <p>You have successfully registered.</p>
              </div>
              }
          </div>
          <CrossIcon>
            <img onClick={() => {
              dispatch(usersAction(null));
              hide();
            }} src={Cross} alt="crossIcon" />
          </CrossIcon>
          {isLoading && <Loader>
            <div className="flex items-center flex-text-center h-100">
              <img src={loaderGif} alt="loader" />
            </div>
          </Loader>}
        </ModalHolder>
      </ModalDialog>
    </Modal>
  );
};

export default RegisterForm;
