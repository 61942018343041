import React from 'react';
import Button from '../../../components/generic/button';
import Container from '../../../components/generic/container';
import {
  Line,
  Lines,
  MobilePara,
  PageContent,
  Para,
  Section,
  SectionContent,
  SectionHeading,
  SectionVideo,
} from '../../../components/styledComponent';
import { ASSETS_CDN_URL, MINT_TIMESTAMP } from '../../../constants/constant';
import { Video } from '../HomeStyled';

const CardanoLand = () => {
  const date = new Date().getTime();
  const isMintAvailable = MINT_TIMESTAMP && date >= MINT_TIMESTAMP;
  return (
    <>
      <Container>
        <Lines>
          <Line />
          <Line />
          <Line />
          <Line mblHide />
        </Lines>
      </Container>
      <Section>
        <Container>
          <PageContent>
            <SectionContent viewHeight="100vh">
              <SectionHeading>FREE NFT</SectionHeading>
              <Para mbl>
                Mint a Small or Medium Cardano Island Land Deed during cNFTcon
                and get a FREE chrome-finish Shelby Cobra 289FIA 3D NFT!
              </Para>
              <Para mbl>
                To buy a Cardano Island Land Deed during cNFTcon 2022, click the
                button below:
              </Para>
              <MobilePara>
                Mint a Small or Medium Cardano Island Land Deed during cNFTcon
                and get a FREE chrome-finish Shelby Cobra 289FIA 3D NFT!
              </MobilePara>
              <Button
                gradients
                props
                left
                disabled
                upper
                pointer={isMintAvailable ? 'pointer' : 'no-drop'}
                mblresponsive
                as="a"
                href={isMintAvailable ? 'https://cardano.virtua.com/' : ''}
                target={isMintAvailable ? '_blank' : ''}
              >
                {isMintAvailable ? 'MINT NOW' : 'COMING SOON'}
              </Button>
            </SectionContent>
            <SectionVideo>
              <Video autoPlay muted loop playsInline cardano>
                <source
                  src={ASSETS_CDN_URL + 'cardano-video.mp4'}
                  type="video/mp4"
                />
              </Video>
            </SectionVideo>
          </PageContent>
        </Container>
      </Section>
    </>
  );
};
export default CardanoLand;
