import {
  beginWallet,
  discord,
  eternalWallet,
  Facebook,
  flintWallet,
  metaMask,
  namiWallet,
  Twitter,
  Youtube,
} from '../assets/images';

export const API_URL = process.env.REACT_APP_SERVER_URL;
export const MINT_TIMESTAMP = process.env.REACT_APP_MINT_TIMESTAMP;
export const ASSETS_CDN_URL = process.env.REACT_APP_ASSET_CDN;

export const WALLETS = {
  polygon: [
    {
      src: metaMask,
      alt: 'MetaMask',
      name: 'MetaMask Wallet',
    },
  ],
  cardano: [
    {
      src: namiWallet,
      alt: 'Nami',
      name: 'Nami Wallet',
      key: 'nami',
      url: 'https://namiwallet.io/',
    },
    {
      src: eternalWallet,
      alt: 'Eternl',
      name: 'Eternl Wallet',
      key: 'eternl',
      url: 'https://eternl.io/',
    },
    {
      src: flintWallet,
      alt: 'Flint',
      name: 'Flint Wallet',
      key: 'flint',
      url: 'https://flint-wallet.com/',
    },
    {
      src: beginWallet,
      alt: 'Begin',
      name: 'Begin Wallet',
      key: 'begin',
      url: 'https://begin.is/',
    },
  ],
};

export const SOCIAL_ICONS = [
  {
    src: Facebook,
    href: 'https://www.facebook.com/VirtuaMetaverse/',
    alt: 'facebook',
  },
  {
    src: Twitter,
    href: 'https://twitter.com/Virtuametaverse',
    alt: 'twitter',
  },
  {
    src: discord,
    href: 'https://discord.com/invite/virtua',
    alt: 'discord',
  },
  {
    src: Youtube,
    href: 'https://www.youtube.com/VirtuaMetaverse',
    alt: 'youtube',
  },
];
