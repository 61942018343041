import styled from 'styled-components';

const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 18px);
  padding: 0.375rem 0.95rem 0.575rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 100%;
  color: #A3A3A3;
  background: #0a0113;
  border: ${props => (props.error ? '1px solid #ED1C24' : '1px solid rgba(255, 255, 255, 0.16)')};
  background-clip: padding-box;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const ErrorInputMessage = styled.span`
  color: #ED1C24;
  display: block;
  padding: 8px 0 4px;
`;

export {Input,ErrorInputMessage};
