import styled from 'styled-components';

const Lines = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  z-index: 2;
  gap: 25%;
  @media (max-width: 768px) {
    width: 85%;
    gap: 0;
  }
`;

const Line = styled.div`
  width: 1px;
  height: 100vh;
  opacity: 0.05;
  border: 1px solid #ffffff;
  @media (max-width: 768px) {
    display: ${(props) => (props.mblHide ? 'none' : 'block')};
  }
`;

const Section = styled.section`
  height: 100vh;
  background: #0a0113;
`;

const SectionContent = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  text-align: ${(props) => (props.shelby ? 'left' : 'left')};
  margin-left: ${(props) => (props.shelby ? 'auto' : '')};
  @media (max-width: 992px) {
    text-align: center;
    width: 100%;
    gap: 16px;
    justify-content: start;
  }
  @media (max-width: 1200px) {
    max-width: 850px;
  }
  @media (max-width: 992px) {
    max-width: 650px;
  }
  @media (max-height: 700px) {
    gap: 5px;
  }
`;

const SectionHeading = styled.h2`
  font-family: 'Neutraface', serif;
  font-weight: 700;
  font-size: 64px;
  text-transform: uppercase;
  color: #fff;
  @media (max-width: 1400px) {
    font-size: 42px;
  }
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const SocialLinks = styled.a`
  background: rgba(10, 1, 19, 0.24);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    padding: 7px;
  }
`;

const Para = styled.p`
  font-size: ${(props) => (props.footer ? '14px' : '16px')};
  color: #fff;
  font-weight: 400;
  line-height: 28px;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.01em;
  padding-inline: ${(props) => (props.heroPara ? '40px' : '')};
  @media (max-width: 992px) {
    padding-inline: ${(props) => (props.heroPara ? '0' : '')};
    font-size: ${(props) => (props.footer ? '12px' : '14px')};
    line-height: 24px;
    display: ${(props) => (props.mbl ? 'none' : '')};
  }
`;

const MobilePara = styled(Para)`
  display: none;
  @media (max-width: 992px) {
    display: block;
    line-height: 22px;
  }
`;

const PageContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  height: 100vh;
  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: 600px) {
    height: calc(100vh - 86px);
    gap: 30px;
  }
`;

const SectionVideo = styled.div`
  width: 55%;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, #b26ee4 0%, #f1209b 100%);
    filter: blur(25px);
    opacity: 0.5;
    z-index: -2;
  }
  &:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100.4%;
    height: 99.2%;
    background: linear-gradient(
      90deg,
      rgba(178, 110, 228, 0.4) 0%,
      rgba(241, 32, 155, 0.4) 100%
    );
    z-index: -1;
    border-radius: 15px;
    @media (max-width: 768px) {
      height: 101%;
    }
    @media (max-width: 600px) {
      height: 101.2%;
    }
  }
  @media (max-width: 1200px) {
    width: 50%;
  }
  @media (max-width: 992px) {
    width: 65%;
  }
  @media (max-width: 600px) {
    width: 95%;
    margin-top: 50px;
  }
`;

export {
  Lines,
  Line,
  SectionHeading,
  Para,
  Section,
  SectionContent,
  SocialLinks,
  MobilePara,
  SectionVideo,
  PageContent,
};
