import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { StateProvider } from './state';
import { INITIAL_STATE as REGISTER_INITIAL_STATE } from './state/register/registerReducers';
import reducers from './state/reducers';
import Header from './components/header';
import styled from 'styled-components';
import './assets/css/reset.css';
import './assets/css/swiper.css';
import Home from './views/home/Home';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper';
import 'swiper/swiper.min.css';
import CardanoLand from './views/home/cardano-land/CardanoLand';
import Footer from './components/footer';

const Wrapper = styled.div`
  position: relative;
`;

const sections = [<Home />, <CardanoLand />];

const Root = () => {
  const initialState = {
    register: REGISTER_INITIAL_STATE,
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const { innerWidth: width } = window;

  const showFooter = () => {
    if (width >= 992) return true;

    return activeIndex !== 0;
  };

  return (
    <StateProvider initialState={initialState} reducer={reducers}>
      <Router>
        <Wrapper id="wrapper">
          <Header />
          <Swiper
            direction={'vertical'}
            slidesPerView={1}
            mousewheel={{
              thresholdDelta: 60,
            }}
            onActiveIndexChange={(e) => setActiveIndex(e.activeIndex)}
            modules={[Mousewheel]}
            className="mySwiper"
          >
            {sections.map((item, index) => (
              <SwiperSlide key={'section_' + index}> {item} </SwiperSlide>
            ))}
          </Swiper>
          {showFooter() && <Footer />}
        </Wrapper>
      </Router>
    </StateProvider>
  );
};

export default Root;
