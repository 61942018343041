import { useState } from 'react';
import { useStateValue } from '../state/index';
import { usersAction } from '../state/register/registerActions';
import { saveUserData } from "../state/register/registerQueries";

const useRegister = () => {
  const [{ register }, dispatch] = useStateValue();
  const [isLoading, setLoading] = useState(false);

  const formData = async (values) => {
    setLoading(true);
    let response = await saveUserData(values);
    dispatch(usersAction(response));
    setLoading(false);
  };

  return [register, formData, isLoading, dispatch];
};

export default useRegister;
