import axios from 'axios';
import {API_URL} from "../../constants/constant";

export const saveUserData = (formData) => {
  return axios
    .post(`${API_URL}api/users/enlist`, formData)
    .then(res => {
      return {
        data: res.data,
        statusCode: res.status
      }
    })
    .catch(err =>  {return {data: err.response?.data, statusCode: err.response?.status}});
};
