const bgColors = {
  primary: '#7159c1',
  secondary: '#9b49c1',
  polyGen: '#8247E5',
  cardano: '#0033AD',
  gradients: 'linear-gradient(90deg, #B26EE4 0%, #F1209B 100%);',
};
const colors = {
  default: '#fff',
};

const viewports = {
  smartphone: '360px',
  tablet: '720px',
  desktop: '1280px',
};

const forms = {
  labelColor: colors.light,
  errorColor: colors.danger,
};

const theme = Object.freeze({
  colors,
  bgColors,
  forms,
  viewports,
});

export default theme;
